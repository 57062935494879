import {Amplify} from "aws-amplify";
import {withAuthenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {ThemeProvider} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {Routes, Route} from "react-router-dom"

import "./App.css";
import React, {useEffect} from "react";
import DiaryForm from "./components/DiaryForm";
import Home from "./components/Home";
import StudentForm from "./components/StudentForm";
import StudentList from "./components/StudentList";
import {selectAuth, setAuthToken, setCredentials} from "./services/authSlice";
import {CircularProgress} from "@mui/material";
import theme from "./theme";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

Amplify.configure({
		Auth: {
			region: "eu-west-2",
			userPoolId: "eu-west-2_KLqxZqIwW",
			userPoolWebClientId: "6clr280urhtk28etcgfjqvtggb",
			identityPoolId: "eu-west-2:3bec509e-83fc-4d7b-98dd-146636869a72",
			mandatorySignIn: true,
		}
	}
)

function App({signOut, user}) {
	const dispatch = useDispatch();
	const auth = useSelector(selectAuth)
	useEffect( () => {
		const loadCredentials = async () => {
			return await Amplify.Auth.currentUserCredentials();
		}
		dispatch(setAuthToken(user.signInUserSession.idToken.jwtToken));
		loadCredentials().then(credentials => dispatch(setCredentials({
			accessKeyId: credentials.accessKeyId,
			secretAccessKey: credentials.secretAccessKey,
			sessionToken: credentials.sessionToken
		})));
	}, [user, dispatch]);

	return auth.token ? (
		<ThemeProvider theme={theme}>
			<Routes>
				<Route element={<Home signOut={signOut} user={user}/>}>
					<Route path="/" element={<StudentList/>}/>
					<Route path="/create-student" element={<StudentForm/>}/>
					<Route path="/modify-student/:id" element={<StudentForm/>}/>
					<Route path="/create-diary/:id" element={<DiaryForm/>}/>
				</Route>
			</Routes>
		</ThemeProvider>
	) : <ThemeProvider theme={theme}><CircularProgress /></ThemeProvider>;
}

export default withAuthenticator(App, {hideSignUp: true});
