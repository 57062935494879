import {AddCircleRounded} from "@mui/icons-material";
import {Container, Typography, TextField, Button, Alert} from "@mui/material";
import {FileUpload} from "primereact/fileupload";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useCreateDiaryMutation, useGetStudentQuery} from "../services/apiSlice";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {selectAuth} from "../services/authSlice";
import AWS from "aws-sdk";

const DiaryForm = () => {
	const initialState = {
		date: dayjs(),
		learnt: "",
		targets: "",
		homework: "",
	}
	const studentId = useParams().id;

	const [fields, setFields] = useState(initialState);
	const [errors, setErrors] = useState(initialState);
	const [alertText, setAlertText] = useState("");
	const [attachments, setAttachments] = useState([]);
	const navigate = useNavigate();
	const auth = useSelector(selectAuth);

	const {data} = useGetStudentQuery({id: studentId});
	const [createDiary] = useCreateDiaryMutation();

	const changeFormChange = (e) => {
		setFields({...fields, [e.target.id]: e.target.value})
	}

	const validate = () => {
		let e = initialState;
		let valid = true;
		if (fields.learnt.length <= 0) {
			e.learnt = "This field cannot be empty"
			valid = false
		}
		if (fields.targets.length <= 0) {
			e.targets = "This field cannot be empty"
			valid = false
		}
		if (fields.homework.length <= 0) {
			e.homework = "This field cannot be empty"
			valid = false
		}
		setErrors(e)
		return valid
	}

	const onSubmit = (e) => {
		e.preventDefault();
		const valid = validate()
		if (valid) {
			createDiary(
				{...fields, attachments, date: fields.date.format("DD.MM.YYYY").toString(), studentId}
			)
			navigate("/")
		}
	}

	const uploadFiles = (e) => {
		const keys = []
		AWS.config.update(auth.credentials)
		const s3 = new AWS.S3({
			region: "eu-west-2",
		});
		e.files.map(f => {
			const k = `${studentId}/${fields.date.format("DD.MM.YYYY").toString()}/${f.name}`;
			keys.push(k);
			return s3.putObject({
				Bucket: "slatifi-diaries",
				Key: k,
				Body: f
			}, function (err, data) {
				if (err) {
					console.log("Error", err);
				}
				if (data) {
					console.log("Upload Success", data);
					setAlertText("Files uploaded successfully");
				}
			})
		})
		setAttachments(keys);
	}

	return <div className="diary-form pb-5">
		<Container maxWidth="xl">
			<Typography variant="h3"
			            sx={{
				            fontWeight: 500,
				            marginTop: "1.5rem",
				            marginBottom: "2rem"
			            }}>Create diary for {data?.first_name}</Typography>
			<form className="grid gap-y-4" onSubmit={onSubmit} action="">
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						format="DD/MM/YYYY"
						label="Lesson date"
						value={fields.date}
						onChange={v => setFields({...fields, date: v})}
					/>
				</LocalizationProvider>
				<TextField
					error={!!errors.learnt}
					id="learnt"
					label="&#128214; What was learnt today"
					value={fields.learnt ?? ""}
					onChange={changeFormChange}
					multiline
					rows={3}
					helperText={errors.learnt ?? ""}
				/>
				<TextField
					error={!!errors.targets}
					id="targets"
					label="&#127919; Targets"
					value={fields.targets ?? ""}
					onChange={changeFormChange}
					multiline
					rows={3}
					helperText={errors.targets ?? ""}
				/>
				<TextField
					error={!!errors.homework}
					id="homework"
					label="&#128337; Homework"
					value={fields.homework ?? ""}
					onChange={changeFormChange}
					multiline
					rows={3}
					helperText={errors.homework ?? ""}
				/>
				{alertText !== "" ? <Alert severity="success">{alertText}</Alert>: ""}
				<FileUpload
					name="demo[]"
					multiple
					maxFileSize={2000000}
					customUpload
					uploadHandler={uploadFiles}
					emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
				/>
				<Button sx={{marginTop: "1.5rem"}} variant="contained" type="submit">
					<AddCircleRounded className="mr-2"/>Create diary
				</Button>
			</form>
		</Container>
	</div>
}

export default DiaryForm;
