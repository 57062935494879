import {createSlice} from "@reduxjs/toolkit";

const initialState = {
	token: "",
	credentials: "",
}

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setToken(state, action) {
			state.token = action.payload.token
		},
		setCredential (state, action) {
			state.credentials = action.payload.credentials
		},
	}
})

export default authSlice.reducer;
const {setToken, setCredential} = authSlice.actions;

export const setAuthToken = token => dispatch => dispatch(setToken({token}));

export const selectAuth = state => state.auth;

export const setCredentials = credentials => dispatch => dispatch(setCredential({credentials}));
