import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"

const baseQuery = fetchBaseQuery({
	baseUrl: "https://api.diaries.slatifi.co.uk/",
	prepareHeaders: (headers, {getState}) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = getState().auth.token
		if (token) {
			headers.set("authorization", `Bearer ${token}`)
		}
		return headers
	},
})

export const api = createApi({
	reducerPath: "api",
	baseQuery: baseQuery,
	tagTypes: ["Student"],
	endpoints: (builder) => ({
		getStudents: builder.query({
			query: () => "students/",
			providesTags: (result, error, arg) =>
				result
					? [...result.map(({id}) => ({type: "Student", id})), "Student"]
					: ["Student"],
		}),
		getStudent: builder.query({
			query: ({id}) => `students/${id}/`,
			providesTags: (result, error, {id}) => [{type: "Student", id: id}],
		}),
		deleteStudent: builder.mutation({
			query: ({id}) => ({
				url: `students/${id}/`,
				method: "DELETE"
			}),
			invalidatesTags: (result, error, {id}) => [{type: "Student", id: id}],
		}),
		createStudent: builder.mutation({
			query: ({data}) => ({
				url: `students/`,
				method: "POST",
				body: data
			}),
			invalidatesTags: ["Student"],
		}),
		modifyStudent: builder.mutation({
			query: ({id, data}) => ({
				url: `students/${id}/`,
				method: "PATCH",
				body: data
			}),
			invalidatesTags: (result, error, {id}) => [{type: "Student", id: id}],
		}),
		createDiary: builder.mutation({
			query: (data) => ({
				url: `diaries/`,
				method: "POST",
				body: data
			}),
		}),
	}),
})

export const {
	useGetStudentsQuery,
	useGetStudentQuery,
	useDeleteStudentMutation,
	useCreateStudentMutation,
	useCreateDiaryMutation,
	useModifyStudentMutation
} = api;
