import React from "react";
import Navbar from "./Navbar";
import {Outlet} from "react-router-dom"

const Home = ({signOut}) => {
	return (
		<div className="App h-fit min-h-screen bg-slate-100">
			<Navbar signOut={signOut}/>
			<Outlet/>
		</div>
	);
}

export default Home;
