import {AddCircleRounded, BorderColorRounded} from "@mui/icons-material";
import {Container, Typography, TextField, Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useCreateStudentMutation, useModifyStudentMutation, useGetStudentQuery} from "../services/apiSlice";
import DynamicField from "./DynamicField";

const StudentForm = () => {
	const initialState = {
		first_name: "",
		last_name: "",
		contacts: [""]
	}
	const id = useParams().id;
	const modify = !!id

	const [fields, setFields] = useState(initialState);
	const [errors, setErrors] = useState(initialState);


	const [createStudent] = useCreateStudentMutation();
	const [modifyStudent] = useModifyStudentMutation();
	const {data} = useGetStudentQuery({id}, {skip: !modify})
	const navigate = useNavigate();

	useEffect(() => {
		if (data) {
			setFields(data);
		}
	}, [data])

	const changeFormChange = (e) => {
		setFields({...fields, [e.target.id]: e.target.value})
	}

	const validate = () => {
		let e = initialState;
		let valid = true;
		if (fields.first_name.length <= 0) {
			e.first_name = "This field cannot be empty"
			valid = false
		}
		if (fields.last_name.length <= 0) {
			e.last_name = "This field cannot be empty"
			valid = false
		}
		e.contacts = fields.contacts.map((c) => {
			if (c.length <= 0) {
				valid = false
				return "This field cannot be empty"
			}
			// eslint-disable-next-line
			if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(c))) {
				valid = false
				return "This is not a valid email address"
			}
			return ""
		})
		setErrors(e)
		return valid
	}

	const onSubmit = (e) => {
		e.preventDefault();
		const valid = validate()
		if (valid) {
			if (modify) {
				const {id, ...rest} = fields;
				modifyStudent({id, data: rest});
				navigate("/");
			} else {
				createStudent({data: fields});
				navigate("/");
			}
		}
	}

	return <div className="student-list">
		<Container maxWidth="xl">
			<Typography variant="h3"
			            sx={{
				            fontWeight: 500,
				            marginTop: "1.5rem",
				            marginBottom: "2rem"
			            }}>{modify ? `Amend ${data?.first_name} ${data?.last_name}` : "Create student"}</Typography>
			<form className="grid gap-y-4" onSubmit={onSubmit} action="">
				<TextField
					error={!!errors.first_name}
					id="first_name"
					label="First name"
					value={fields.first_name ?? ""}
					onChange={changeFormChange}
					helperText={errors.first_name ?? ""}
				/>
				<TextField
					error={!!errors.last_name}
					id="last_name"
					label="Last name"
					value={fields.last_name ?? ""}
					onChange={changeFormChange}
					helperText={errors.last_name ?? ""}
				/>
				<DynamicField
					name="Contact"
					errors={errors.contacts}
					setErrors={(err) => setErrors({...errors, contacts: err})}
					value={fields.contacts}
					onChange={(e) => {
						let newContacts = fields.contacts
						newContacts[parseInt(e.target.name)] = e.target.value
						setFields({...fields, contacts: newContacts})
					}}
					setValue={v => setFields({...fields, contacts: v})}
				/>
				<Button sx={{marginTop: "1.5rem"}} variant="contained" type="submit">
					{modify ? <BorderColorRounded className="mr-2"/> : <AddCircleRounded className="mr-2"/>}
					{modify ? "Modify student" : "Add student"}
				</Button>
			</form>
		</Container>
	</div>
}

export default StudentForm;
