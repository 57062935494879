import React, {useState} from "react";
import {Typography, Container, Button, Box} from "@mui/material"
import {DataGrid} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {useDeleteStudentMutation, useGetStudentsQuery} from "../services/apiSlice";
import {CancelRounded, BorderColorRounded, AddCircleRounded} from "@mui/icons-material";
import ConfirmationDialog from "./ConfirmationDialog";
import LinkButton from "./LinkButton";


const StudentList = () => {
	const {data} = useGetStudentsQuery();
	const [deleteStudent] = useDeleteStudentMutation();
	const [toDelete, setToDelete] = useState({});
	const [open, setOpen] = React.useState(false);
	const navigate = useNavigate()

	const columns = [
		{
			field: "fullName",
			headerName: "Full name",
			description: "This column has a value getter and is not sortable.",
			flex: 0.4,
			align: "center",
			headerAlign: "center",
			valueGetter: (params) =>
				`${params.row.first_name || ""} ${params.row.last_name || ""}`,
		},
		{
			field: "first_name",
			headerName: "First name",
			align: "center",
			headerAlign: "center",
			flex: 0.2,
		},
		{
			field: "last_name",
			headerName: "Last name",
			align: "center",
			headerAlign: "center",
			flex: 0.2,
		},
		{
			field: "contacts",
			headerName: "Contacts",
			align: "center",
			headerAlign: "center",
			flex: 0.4,
			valueFormatter: params => {
				let val = "";
				params.value.map(s => val += `${s}\n`)
				return val;
			}
		},
		{
			field: "actions",
			headerName: "Actions",
			align: "center",
			headerAlign: "center",
			flex: 0.5,
			type: "actions",
			getActions: params => {
				return [
					<Button color="secondary" onClick={() => navigate(`/modify-student/${params.id}`)}><BorderColorRounded className="mr-2"/> Modify</Button>,
					<Button color="error" onClick={() => handleOpen(params.row)}><CancelRounded className="mr-2"/> Delete</Button>,
					<Button color="primary" onClick={() => navigate(`/create-diary/${params.id}`)}><AddCircleRounded className="mr-2"/> Create diary</Button>,
				]
			}
		}
	];

	const deleteCallback = () => {
		if (toDelete.id) {
			deleteStudent({id: toDelete.id});
		}
	}


	const handleOpen = (student) => {
		setToDelete(student);
		setOpen(true);
	}

	return <div className="student-list">
		<Container maxWidth="xl">
			<Typography variant="h3"
			            sx={{fontWeight: 500, marginTop: "1.5rem", marginBottom: "2rem"}}>Students</Typography>
			<Box sx={{height: 400, width: "100%"}}>
				<DataGrid
					rows={data ?? []}
					className="text-center"
					columns={columns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					disableSelectionOnClick
					experimentalFeatures={{newEditingApi: true}}
					sx={{"& .MuiDataGrid-cellContent": {whiteSpace: "pre"}}}
				/>
			</Box>
			<LinkButton href="/create-student" sx={{marginTop: "1.5rem"}} variant="contained"><AddCircleRounded className="mr-2"/> Add
				student</LinkButton>
			<ConfirmationDialog
				title="Deletion confirmation"
				body={`Are you sure you would like to delete ${toDelete.first_name ?? ""} ${toDelete.last_name ?? ""}`}
				callback={deleteCallback}
				open={open}
				setOpen={setOpen}
			/>
		</Container>
	</div>
};

export default StudentList;
