import {AddCircleRounded, RemoveCircleOutlined} from "@mui/icons-material";
import {TextField} from "@mui/material";
import React from "react";

const DynamicField = ({name, errors, setErrors,onChange, value, setValue}) => {
	const addField = () => {
		setValue([...value, ""])
		setErrors([...errors, ""])
	}
	const removeField = () => {
		setValue(value.slice(0, -1))
		setErrors(errors.slice(0, -1))
	}

	return <div>
		{value.map((contact, i) => {
			return <div className="flex items-center mb-1" key={i}>
				<TextField fullWidth label={name} value={value[i]} name={`${i}`} onChange={onChange} error={!!errors[i]} helperText={errors[i]}/>
				{i === value.length -1 ? <AddCircleRounded sx={{marginLeft:".3rem"}} color="primary" fontSize="large" onClick={addField}/> : ""}
				{i === value.length -1 && i !== 0 ? <RemoveCircleOutlined sx={{marginLeft:".3rem"}} color="error" fontSize="large" onClick={removeField}/> : ""}
			</div>
		})}
	</div>
};

export default DynamicField;
