import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./services/authSlice";
import {api} from "./services/apiSlice";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		[api.reducerPath]: api.reducer,
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware)
})
